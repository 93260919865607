@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  font-family: Inter, sans;
  user-select: none;
}

.mainWrapper {
  position: relative;
}

.video {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.actionWrapper {
  position: absolute;
  top: 90vh;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-height: 700px) {
  .actionWrapper {
    top: 85vh;
  }
}

@media (max-height: 500px) {
  .actionWrapper {
    top: 80vh;
  }
}

@media (max-height: 350px) {
  .actionWrapper {
    top: 75vh;
  }
}

.blur {
  position: absolute;
  backdrop-filter: blur(5px);
  -webkit-mask: linear-gradient(transparent, black 50%);
  mask: linear-gradient(transparent, black 50%);
  top: 80vh;
  left: 0;
  height: 20vh;
  width: 100%;
  z-index: 2;
}

.actionButton {
  border: solid 1px #ffffff;
  width: 64px;
  height: 64px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 100ms ease;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
}

.recordingTimeWrapper {
  width: 150px;
  transition: all 300ms ease;
}

.actionButton > div {
  display: flex;
  align-items: center;
}

.actionButton:hover {
  opacity: 0.9;
}

.actionButton:active {
  opacity: 0.8;
}

.recordingTime {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  color: #8f3131;
  font-weight: 600;
}

.recordingTime > span {
  width: 70px;
  text-align: center;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spinner 1.5s linear infinite;
}

.waveAnimationWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 20px;
  color: #8f3131;
  width: 180px;
  transition: all 300ms ease;
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3);
  }

  30% {
    transform: scaleY(1);
  }

  60% {
    transform: scaleY(0.5);
  }

  80% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.6);
  }
}

.waveAnimation {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100px;
  height: 20px;

  span {
    width: 3px;
    border-radius: 3px;
    height: 100%;
    background: linear-gradient(to top, #644103, #ab7305);
    border-radius: 3px;
    animation: bounce 2.2s ease infinite alternate;
    content: "";
    &:nth-of-type(1) {
      animation-delay: -1.5s;
    }
    &:nth-of-type(2) {
      animation-delay: -2.1s;
    }
    &:nth-of-type(3) {
      animation-delay: -0.5s;
    }
    &:nth-of-type(4) {
      animation-delay: -3.7s;
    }
    &:nth-of-type(5) {
      animation-delay: -2.5s;
    }
    &:nth-of-type(6) {
      animation-delay: -3.7s;
    }
    &:nth-of-type(7) {
      animation-delay: -1.2s;
    }
    &:nth-of-type(9) {
      animation-delay: -1.9s;
    }
    &:nth-of-type(9) {
      animation-delay: -3.7s;
    }
  }
}
